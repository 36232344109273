@media screen and (max-width: 400px) {
    #features {
      padding: 20px;
      width: 111%;
    }
    #about,
    #services,
    #testimonials,
    #team,
    #contact,
    #footer {
      width: 110%;
    }
  
    #portfolio {
      width: 110%;
    }
  }
  